import {
    ref, Ref, h, watch,
} from 'vue';
import { TippyOptions, useTippy } from 'vue-tippy';
import TippyComposableDefaultContent from '../components/Tippy/TippyComposableDefaultContent.vue';
import { assignTippyButtonRole, registerTippyInstance, unregisterTippyInstance } from '../helpers/tippyManager';

type ElementType = Element | (() => Element) | Ref<Element> | Ref<Element | undefined>
interface SettingsInterface {
    mount: boolean;
    appName: string;
}
interface ExtendedTippyOptions extends TippyOptions {
    // if trigger action is click, you have to click once again to close tippy,
    // closeAfterClickMs prop allows to specify in how much milliseconds tippy should automatically close
    closeAfterClickMs?: number,
    isContentReactive?: boolean,
}

// Sometimes v-if can cause it to not work because tippy reference changes, use v-show instead
// Be cautious when using v-show as it might still render the Tippy instance even when it's not needed,
// potentially impacting performance by loading unnecessary content.
// Previously, we encountered bugs related to v-show causing double rendering.
const useTippyPopup = (el: ElementType, opts?: ExtendedTippyOptions, settings?: SettingsInterface) => {
    const tippyCloseTrigger = ref(false);
    const isTippyOpen = ref(false);

    const closeTippy = () => {
        tippyCloseTrigger.value = !tippyCloseTrigger.value;
    };

    const { content: tippyContent, closeAfterClickMs, isContentReactive = false } = opts;

    const tippy = useTippy(el, {
        theme: 'default',
        onShow(instance) {
            isTippyOpen.value = true;
            if (closeAfterClickMs) {
                setTimeout(() => {
                    instance.hide();
                }, closeAfterClickMs);
            }
        },
        onHidden() {
            isTippyOpen.value = false;
        },
        onCreate(instance) {
            assignTippyButtonRole(instance);
        },
        ...opts,
        content: !isContentReactive
            ? h(
                    h(
                        TippyComposableDefaultContent,
                        null,
                        () => (
                            typeof tippyContent === 'string' ? tippyContent : h(tippyContent, { closeTippy, isTippyOpen: isTippyOpen.value })
                        ),
                    ),
                 )
            : tippyContent,
    }, settings);

    // Register the Tippy instance
    registerTippyInstance(tippy);

    // Unregister the Tippy instance when destroyed
    const unregisterTippy = () => {
        unregisterTippyInstance(tippy);
    };

    // we need this because there is no other way how to pass hide function to tippy content
    watch(() => tippyCloseTrigger.value, () => {
        tippy.hide();
    });

    return {
        ...tippy,
        destroy() {
            unregisterTippy();
            tippy.destroy();
        },
    };
};

export default useTippyPopup;
